//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {},
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("user/getHistory", {
        ...query
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "日期",
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "datetime"
      }, {
        title: "报表类别",
        show: true,
        name: "type",
        width: 250
      }, {
        title: "商店名称",
        show: true,
        name: "name",
        width: 250,
        type: "link",
        getLink: item => {
          if (!item.url) {
            return {
              route: "/",
              text: item.name,
              target: "_blank"
            };
          }
          const route = this.$router.resolve(item.url).route;
          return {
            route: {
              ...route,
              query: {
                ...route.query,
                _state: item.info === "{}" ? undefined : item.info
              }
            },
            text: item.name,
            target: "_blank"
          };
        }
      }, {
        title: "期间从",
        show: true,
        name: "report_date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "期间直至",
        show: true,
        name: "report_date2",
        width: 100,
        filter: "dateRange",
        type: "date"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};